<template>
  <div class="subject_data">
    <pageTitle pageTitle="导出数据"></pageTitle>
    <div v-if="isBilingual" style="text-align: right">
      <vh-radio-group
        v-model="languageType"
        @change="handleLanguageTypeChange"
        text-color="#fff"
        fill="#FB3A32"
      >
        <vh-radio-button :label="1">中文用户</vh-radio-button>
        <vh-radio-button :label="2">英文用户</vh-radio-button>
      </vh-radio-group>
    </div>
    <div class="subject_data__panel">
      <user-registration
        :isBilingual="isBilingual"
        :languageType="languageType"
        :webinarOrSubjectId="webinarOrSubjectId"
        :signUpPageType="signUpPageType"
      ></user-registration>
    </div>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import UserRegistration from '../PlatformModule/UserRegistration/index';
  export default {
    components: {
      PageTitle,
      UserRegistration
    },
    provide() {
      return {
        subject_verify_live: () => this.subject_verify_live,
        baseInfo_main: () => this.baseInfo,
        getLivesTotal: () => this.livesTotal
      };
    },
    data() {
      return {
        webinarOrSubjectId: this.$route.params.id, // 路径是 /data/xxx
        signUpPageType: 'subject',
        baseInfo: {
          theme_color: 'red',
          tab_verify_title: '我已报名',
          tab_form_title: '活动报名',
          auth_button: '立即预约',
          title: '',
          intro: '',
          cover: '',
          audit_fail: 0,
          is_audit: false //是否开启报名审核
        },
        subject_verify_live: false,
        livesTotal: 0,

        isBilingual: false,
        languageType: 1
      };
    },
    created() {
      this.$EventBus.$on('livesTotal', res => {
        this.livesTotal = res;
        console.log('-----aslivesTotallivesTotallivesTotal--', res);
      });
      this.initComp();
    },
    methods: {
      initComp() {
        try {
          Promise.all([
            this.getSubjectInfo(),
            this.getFormBaseInfo(),
            this.getSubjectVerifyInfo(),
            this.getSubjectWebinarlist()
          ]);
        } catch (error) {
          console.log('data.vue:', error);
        }
      },
      // 获取专题是否是双语双流类型
      getSubjectInfo() {
        return this.$fetch('subjectInfo', {
          subject_id: this.webinarOrSubjectId
        }).then(res => {
          if (res.code == 200) {
            //是否双语双流专题(专题类型：1.普通专题，2.老系统双语（按普通专题处理），3.双语专题)
            this.isBilingual = res.data.webinar_subject.type === 3;
          }
          return res;
        });
      },
      // 获取专题下的表单基本信息
      getFormBaseInfo() {
        return this.$fetch('regFromGet', {
          subject_id: this.webinarOrSubjectId,
          language_type: this.languageType
        }).then(res => {
          if (res.code === 200) {
            this.baseInfo = Object.assign({}, res.data, {
              is_audit: res.data.is_audit ? true : false
            });
          }
          return res;
        });
      },
      getSubjectVerifyInfo() {
        // 获取专题的观看限制
        return this.$fetch('subjectVerifyInfo', {
          subject_id: this.webinarOrSubjectId
        }).then(res => {
          if (res.code == 200) {
            this.subject_verify_live = res.data.user_choice == 1 ? true : false;
          }
          return res;
        });
      },
      // 获取专题下的活动列表总数
      getSubjectWebinarlist() {
        return this.$fetch('getSubjectWebinarlist', {
          subject_id: this.webinarOrSubjectId
        }).then(res => {
          if (res && res.code === 200) {
            this.livesTotal = res.data.total;
          }
          return res;
        });
      },
      handleLanguageTypeChange() {
        this.getFormBaseInfo();
      }
    }
  };
</script>
